
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ServerType, UtBoardItem } from "prometheus-synced-ui";
import { LITE_FEATURE, UtBoardDetails } from "@/domain";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { IUtBoardService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class UtBoardsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get utBoardService() {
    return this.container.resolve<IUtBoardService>(S.UT_BOARD_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.boards, (s) => s.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/UtBoardCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/UtBoardUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  boards: UtBoardItem[] = [];
  loading: boolean = true;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.boards = await this.utBoardService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(board: UtBoardDetails) {
    this.boards.push(board.toItem());
  }

  update(board: UtBoardDetails) {
    const index = _.findIndex(this.boards, (b) => b.id == board.id);
    if (index != -1) {
      this.boards.splice(index, 1, board.toItem());
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const board = _.find(this.boards, (b) => b.id == id)!;
      const result = await this.utBoardService.update(id, {
        serialNumber: board.serialNumber,
        equipmentType: board.equipmentType,
        equipmentBrandId: board.equipmentBrand.id,
        pvNumber: board.pvNumber,
        disabled: !board.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
